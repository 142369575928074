.pages-listing-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  column-gap: 32px;
}

.listing-label {
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 8px;
}

.page-list {
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;

  /* TO DO: Analyze if min-width and min-height are good ideas */
  min-width: 200px;
  min-height: 48px;
  display: block;
}
