.listing-pages {
  margin-top: 16px;
  margin-bottom: 32px;
  text-align: center;
}

.first-pages-listing {
  margin-bottom: 16px;
}

.reset-button {
  margin-top: 32px;
}
