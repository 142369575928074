@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");

:root {
  --light-pink: #ff93d4;
  --medium-pink: #ea008d;
  --dark-pink: #d84a9f;
  --light-grey: #adadad;
  --medium-grey: #7d7d7d;
}

body {
  font-family: "Inter", sans-serif;
  background-color: var(--light-pink);
  box-sizing: content-box;
  margin: 0;
  padding: 0;
}

input {
  outline: none;
  border: 1px solid var(--medium-grey);
  min-width: 238px;
  font-size: 20px;
  padding-left: 12px;
}

button {
  outline: none;
  border-color: transparent;
  cursor: pointer;
}

.round-button {
  background-color: var(--light-pink);
  border-radius: 100%;
  width: 44px;
  height: 44px;
}

.white-icon {
  /* Weird trick to make the icon white */
  filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(201deg)
    brightness(104%) contrast(101%);
}

.icon {
  margin: 0 auto;
  height: 24px;
}

.text-secondary {
  font-weight: 600;
  color: var(--light-grey);
  font-size: 14px;
}

.center-parent-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100vh;
}

.parent-container {
  background-color: white;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 8px;
  padding: 32px 72px;
  padding-bottom: 48px;
}

.title {
  margin: 0;
  text-align: center;
}

@media (max-width: 500px) {
  .parent-container {
    padding: 32px;
  }
}

.primary-button {
  background-color: var(--light-pink);
  color: white;
  font-size: 20px;
  line-height: 24px;
  border-radius: 8px;
  width: 100%;
  padding: 20px;
  font-weight: 700;
}

.toast {
  background-color: white;
  padding: 16px;
  margin-top: 24px;

  display: flex;
  flex-direction: column;
  row-gap: 16px;

  border-radius: 8px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.toast p {
  font-size: 14px;
}

.toast button {
  margin-left: auto;
  max-width: 70px;
  align-self: flex-end;
  padding: 0;
  margin: 0;
}

.text-button {
  background-color: transparent;
  color: var(--dark-pink);
  font-weight: 700;
  font-size: 14px;
}

.toast {
  position: fixed;
  bottom: 50vh;
  right: 16px;
}

.toast-transition-enter {
  transform: translateX(calc(100% + 16px));
}

.toast-transition-enter-active {
  transform: none;
  transition: transform 200ms;
}

.toast-transition-exit {
  transform: none;
}

.toast-transition-exit-active {
  transform: translateX(calc(100% + 16px));
  transition: transform 200ms;
}

.toast-transition-exit-done {
  transform: translateX(calc(100% + 16px));
}

@media (max-width: 1200px) {
  .toast {
    bottom: 16px;
  }

  .toast-transition-enter {
    transform: translateY(calc(100% + 16px));
  }

  .toast-transition-enter-active {
    transform: none;
    transition: transform 200ms;
  }

  .toast-transition-exit {
    transform: none;
  }

  .toast-transition-exit-active {
    transform: translateY(calc(100% + 16px));
    transition: transform 200ms;
  }

  .toast-transition-exit-done {
    transform: translateY(calc(100% + 16px));
  }
}

@media (max-width: 500px) {
  .toast {
    padding: 8px 16px;
    bottom: 32px;
    row-gap: 8px;

    left: 16px;
    right: 16px;

    box-shadow: 0px 8px 8px rgba(0, 0, 0, 0.25);
  }

  .toast-transition-enter {
    transform: translateY(calc(100% + 32px));
  }

  .toast-transition-enter-active {
    transform: none;
    transition: transform 200ms;
  }

  .toast-transition-exit {
    transform: none;
  }

  .toast-transition-exit-active {
    transform: translateY(calc(100% + 32px));
    transition: transform 200ms;
  }

  .toast-transition-exit-done {
    transform: translateY(calc(100% + 32px));
  }
}
